import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {from, Observable, of} from 'rxjs';
import {fromPromise} from 'rxjs/internal/observable/fromPromise';
import { catchError, tap, switchMap } from 'rxjs/operators';
import {AuthService} from "../shared/auth.service";


/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {

        if (!this.authService.isAuthenticated()) {
            return fromPromise(this.authService.authenticate()).pipe(switchMap((result) => {
                 return next.handle(
                    req.clone({
                        headers: req.headers.append('OpenIdToken', this.authService.getIdToken())
                    })
                );
            }));
        }

        return next.handle(
            req.clone({
                headers: req.headers.append('OpenIdToken', this.authService.getIdToken())
            })
        );
    }
}
