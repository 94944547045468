//Core modules
import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router, ActivatedRouteSnapshot, UrlSegment} from "@angular/router";

@Component({
    selector: 'breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
    breadcrumbs: {
        label: string;
        path: string
    }[] = [];

    title = null

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.router.events.subscribe(event => {
            this.breadcrumbs = [];
            this.parseRoute(this.router.routerState.snapshot.root);
        })
    }

    parseRoute(node: ActivatedRouteSnapshot) {
        if (node.data['breadcrumb']) {
            this.breadcrumbs.push(node.data['breadcrumb']);
        }
        if (node.firstChild) {
            this.parseRoute(node.firstChild);
        }
    }
}