//Core modules
import {Component} from "@angular/core";
//External modules
import {Router} from "@angular/router";
import { version } from '../../../../package.json';

const AVAILABLE_LANG:any = ['fr', 'en'];

@Component({
    selector: 'root',
    templateUrl: './root.component.html',
    styleUrls: ['./root.component.scss']
})
export class RootComponent {
    public version: string = version;
    constructor(private router : Router){
    }
    ngAfterViewInit(): void {
    }
}
