window['__env'] = window['__env'] || []

export const environment = {
    name: window['__env']['name'] || 'dev',
    production: window['__env']['production'] || false,
    oauth_server: window['__env']['oauth_server'] || 'https://id-dev.keo.net',
    client_redirect_uri: window['__env']['client_redirect_uri'] || 'http://localhost:4200/dashboard',
    client_id: window['__env']['client_id'] || 'minHwwqB7aP_1J2VmRBQ6XbV3dsa',
    client_secret: window['__env']['client_secret'] || 'SCATtO4W4hxXumFxdrif98Q7Mqwa',
    register_api_url: window['__env']['register_api_url'] || 'https://register-api.dev.kadrige.com/app_dev.php/api',
    users_list_api_url: window['__env']['users_list_api_url'] || 'https://iadmin.preprod.kadrige.com/api/v3/users/kadrige'
};
