//import "./polyfills.ts";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import {enableProdMode} from "@angular/core";
import {environment} from "./environments/environment";
import {AppScopes} from "./app/app.scopes";
import {AppModule} from "./app/app.module";

if (environment.production) {
    enableProdMode();
}
let kadrigeIdentity = window['kadrigeIdentity'];
let scopes = AppScopes.getAll();
scopes.push('openid');

kadrigeIdentity.init({
    app : 'REGISTER',
    oauth_server : environment.oauth_server,
    redirect_uri : environment.client_redirect_uri,
    client_id : environment.client_id,
    client_secret : environment.client_secret,
    scopes: scopes,
    env : environment.name
});

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));