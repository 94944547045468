//Core modules
import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";

//External modules
//Custom modules
import {AppRoutingModule} from "./app.routing";
import {PageModule} from "./components/page/page.module";
//Custom components
import {RootComponent} from "./components/root/root.component";
import {SidebarComponent} from "./components/sidebar/sidebar.component";
import {BreadcrumbComponent} from "./components/breadcrumb/breadcrumb.component";
import {AuthService} from "./shared/auth.service";
import {AuthGuard} from "./shared/auth-guard.service";
import {NotFoundComponent} from "./components/not-found/not-found.component";
import {HttpClientModule} from "@angular/common/http";
import {httpInterceptorProviders} from "./http";

@NgModule({
    declarations: [
        RootComponent,
        SidebarComponent,
        BreadcrumbComponent,
        NotFoundComponent
    ],
    imports: [
        //Core modules
        BrowserModule,
        FormsModule,
        HttpClientModule,
        //Custom modules
        AppRoutingModule,
        //PageModule
    ],
    exports: [
    ],
    providers: [
        AuthGuard,
        AuthService,
        httpInterceptorProviders
    ],
    bootstrap: [
        RootComponent
    ]
})
export class AppModule { }
