//Core modules
import {Component} from "@angular/core";
import {AuthService} from "../../shared/auth.service";
import {AppScopes} from "../../app.scopes";

@Component({
    selector: 'sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
    public authService;

    constructor(private auth : AuthService) {
        this.authService = auth;

        //change this

        //override accordeon methods

    }

    collapseMenus() {

    }

    showInstance():boolean {
        return this.authService.hasScope(AppScopes.RIGHT_INSTANCE_CONSULT);
    }

    showConf():boolean {
        return this.authService.hasScope(AppScopes.RIGHT_CONFIG_CONSULT);
    }

    showNewConf():boolean {
        return this.authService.hasScope(AppScopes.RIGHT_INSTANCE_CREATE);
    }

    showConfList():boolean {
        return this.authService.hasScope(AppScopes.RIGHT_ACTION_START_PROVISIONING);
    }

    showApp():boolean {
        return this.authService.hasScope(AppScopes.RIGHT_APP_ADMIN);
    }

    showNewApp():boolean {
        return this.authService.hasScope(AppScopes.RIGHT_APP_ADMIN);
    }

    showKubernatesNS():boolean {
        return this.authService.hasScope(AppScopes.RIGHT_DATACENTER_ADMIN) || this.authService.hasScope(AppScopes.RIGHT_SUPER_ADMIN);
    }

    showTenant():boolean {
        return this.authService.hasScope(AppScopes.RIGHT_TENANT_CONSULT);
    }

    showScheduledTasks():boolean {
        return this.authService.hasScope(AppScopes.RIGHT_ACTION_START_PROVISIONING);
    }

    showNewTenant():boolean {
        return this.authService.hasScope(AppScopes.RIGHT_TENANT_ADMIN);
    }

    showDatacenter():boolean {
        return this.authService.hasScope(AppScopes.RIGHT_DATACENTER_CONSULT);
    }


    showNewDatacenter():boolean {
        return this.authService.hasScope(AppScopes.RIGHT_DATACENTER_ADMIN);
    }

    showUserGroup():boolean {
        return this.authService.hasScope(AppScopes.RIGHT_SUPER_ADMIN) || this.authService.hasScope(AppScopes.RIGHT_GROUP_SIMPLE) || this.authService.hasScope( AppScopes.RIGHT_GROUP_ADVANCED);
    }
}
