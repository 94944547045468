//Core modules
import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
//Custom components
import {NotFoundComponent} from "./components/not-found/not-found.component";

const appRoutes: Routes = [
    { path: '', loadChildren: './components/page/page.module#PageModule'},
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {}




