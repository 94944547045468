import {Injectable} from "@angular/core";

@Injectable()
export class AuthService {
    private isAuthenticating : boolean = false;

    authenticate() {

        return new Promise(
            function (resolve, reject) {

                window['kadrigeIdentity'].authenticate().then((data) => {

                    if ( window['kadrigeIdentity'].authenticatedUsingAuthCode === undefined || window['kadrigeIdentity'].authenticatedUsingAuthCode()) {
                        //to avoid keepin auth parameter in the url (js sdk need it)
                        localStorage.removeItem('dashboard_filters');
                        window.location.href = '/dashboard';
                    } else {
                        resolve();
                    }
                }).catch(err => {
                    console.log(err.message);
                    reject(err.message);
                });
            }
        );

    }

    isAuthenticated():boolean {
        return window['kadrigeIdentity'].isAuthenticated();
    }

    getUsername():string {
        return window['kadrigeIdentity'].getUsername();
    }

    getScopes():Array<String> {
        return window['kadrigeIdentity'].getScopes();
    }

    getIdToken():string {
        return window['kadrigeIdentity'].getIdToken();
    }

    hasScope(scope : String):boolean {
        let scopes = this.getScopes();
        return scopes.indexOf(scope) >= 0;
    }
}