export class AppScopes {

    public static RIGHT_ACTION_REJECT = 'KIS/urn:registry:right:action:reject';
    public static RIGHT_ACTION_CANCEL = 'KIS/urn:registry:right:action:cancel';
    public static RIGHT_ACTION_REQUEST_INFOS = 'KIS/urn:registry:right:action:request_infos';
    public static RIGHT_ACTION_VALIDATE = 'KIS/urn:registry:right:action:validate';
    public static RIGHT_ACTION_START_PROVISIONING = 'KIS/urn:registry:right:action:start_provisioning';
    public static RIGHT_ACTION_MARK_AS_DONE = 'KIS/urn:registry:right:action:mark_as_done';
    public static RIGHT_APP_ADMIN = 'KIS/urn:registry:right:application:admin';
    public static RIGHT_APP_CONSULT = 'KIS/urn:registry:right:application:consult';
    public static RIGHT_CONFIG_CONSULT = 'KIS/urn:registry:right:configuration:consult';
    public static RIGHT_DATACENTER_ADMIN = 'KIS/urn:registry:right:datacenter:admin';
    public static RIGHT_DATACENTER_CONSULT = 'KIS/urn:registry:right:datacenter:consult';
    public static RIGHT_INSTANCE_CONSULT_ALL = 'KIS/urn:registry:right:instance:admin_consult';
    public static RIGHT_INSTANCE_CONSULT = 'KIS/urn:registry:right:instance:consult';
    public static RIGHT_INSTANCE_CREATE = 'KIS/urn:registry:right:instance:create';
    public static RIGHT_INSTANCE_TURN_ON = 'KIS/urn:registry:right:instance:turn_on';
    public static RIGHT_INSTANCE_TURN_OFF = 'KIS/urn:registry:right:instance:turn_off';
    public static RIGHT_TENANT_CONSULT = 'KIS/urn:registry:right:tenant:consult';
    public static RIGHT_TENANT_ADMIN = 'KIS/urn:registry:right:tenant:admin';
    public static RIGHT_VARIANT_CONSULT = 'KIS/urn:registry:right:variant:consult';
    public static RIGHT_VARIANT_ADMIN = 'KIS/urn:registry:right:variant:admin';
    public static RIGHT_VERSION_CONSULT = 'KIS/urn:registry:right:version:consult';
    public static RIGHT_VERSION_ADMIN = 'KIS/urn:registry:right:version:admin';
    public static RIGHT_LIVE_EDIT_SIMPLE = 'KIS/urn:registry:right:live_edit:simple';
    public static RIGHT_LIVE_EDIT_ADVANCED = 'KIS/urn:registry:right:live_edit:advanced';
    public static RIGHT_ADMINISTRATION = 'KIS/urn:registry:right:administration:admin';
    public static RIGHT_SUPER_ADMIN = 'KIS/urn:registry:right:super_admin';
    public static RIGHT_GROUP_ADVANCED= 'KIS/urn:registry:right:group:advanced';
    public static RIGHT_GROUP_SIMPLE= 'KIS/urn:registry:right:group:simple';

    static getAll() : Array<String> {
        return [
            AppScopes.RIGHT_ACTION_REJECT, AppScopes.RIGHT_ACTION_CANCEL,
            AppScopes.RIGHT_ACTION_REQUEST_INFOS, AppScopes.RIGHT_ACTION_VALIDATE,
            AppScopes.RIGHT_ACTION_START_PROVISIONING, AppScopes.RIGHT_ACTION_MARK_AS_DONE,
            AppScopes.RIGHT_APP_ADMIN, AppScopes.RIGHT_APP_CONSULT,
            AppScopes.RIGHT_CONFIG_CONSULT, AppScopes.RIGHT_DATACENTER_ADMIN,
            AppScopes.RIGHT_DATACENTER_CONSULT, AppScopes.RIGHT_INSTANCE_CONSULT,
            AppScopes.RIGHT_INSTANCE_CREATE, AppScopes.RIGHT_INSTANCE_TURN_ON,
            AppScopes.RIGHT_INSTANCE_TURN_OFF, AppScopes.RIGHT_TENANT_CONSULT,
            AppScopes.RIGHT_TENANT_ADMIN, AppScopes.RIGHT_VARIANT_CONSULT,
            AppScopes.RIGHT_VARIANT_ADMIN, AppScopes.RIGHT_VERSION_CONSULT,
            AppScopes.RIGHT_VARIANT_ADMIN, AppScopes.RIGHT_VERSION_CONSULT,
            AppScopes.RIGHT_VERSION_ADMIN, AppScopes.RIGHT_LIVE_EDIT_SIMPLE, AppScopes.RIGHT_LIVE_EDIT_ADVANCED,
            AppScopes.RIGHT_ADMINISTRATION, AppScopes.RIGHT_GROUP_ADVANCED, AppScopes.RIGHT_GROUP_SIMPLE,
            AppScopes.RIGHT_SUPER_ADMIN, AppScopes.RIGHT_INSTANCE_CONSULT_ALL
        ]
    }
}