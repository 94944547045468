import {Injectable} from "@angular/core";
import {CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router} from "@angular/router";
import {AuthService} from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService) {}

    hasAccess(allowedScopes): Promise<boolean> {
        if(allowedScopes == null || allowedScopes.length==0){
            return Promise.resolve(true);
        }

        for(let i = 0; i < allowedScopes.length; i++) {
            if (this.authService.hasScope(allowedScopes[i])) {
                return Promise.resolve(true);
            }
        }

        return Promise.resolve(false);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        if(!this.authService.isAuthenticated()){
            return new Promise<boolean>(((resolve, reject) => {
                this.authService.authenticate().then(()=>{
                    this.hasAccess(route.data['roles']).then(()=>{
                        resolve(true);
                    }).catch(()=>{
                        resolve(false);
                    })
                });
            }));
        }

        return this.hasAccess(route.data['roles']);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.canActivate(route, state);
    }
}