//Core modules
import {Component, Input} from "@angular/core";

@Component({
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {

    @Input() filters : Array<Object>;
    @Input() documents : Array<any>;
    @Input() filtersResult : Array<any>;

    constructor() {}
}